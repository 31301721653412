<template>
  <div class="transport-strips-area-container">
    <div class="transport-strips-area">
      <TransportPanelTimeline
        :duration="duration"
        :step="step"
        :horizontalBarHeight="horizontalBarHeight"
      />

      <!-- <div>{{ selectedStrips }}</div> -->

      <div
        class="transport-track"
        v-for="(track, trkIdx) in tracks"
        :key="track.id"
        :style="{ height: `${track.height || tracksHeight}px` }"
      >
        <TransportPanelStrip
          v-for="(strip, stripIdx) in track.strips"
          :key="`${track.id}-${strip.id}`"
          :step="step"
          :track="track"
          :strip="strip"
          :selected="selectedStrips.find(s => s.trkIdx === trkIdx && s.strip.id === strip.id)"
          @click="selectStrip(trkIdx, strip, $event)"
          @resize="resizeStrip(track.id, trkIdx, stripIdx, $event)"
          @resizeEnd="resizeStripEnd(trkIdx, stripIdx, $event)"
          @drag="dragStrip(track.id, trkIdx, stripIdx, $event)"
          @dragEnd="dragStripEnd(trkIdx, stripIdx, $event)"
          @changeTrack="changeStripTrack(trkIdx, stripIdx, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TransportPanelTimeline from './Timeline.vue';
import TransportPanelStrip from './Strip.vue';

export default {
  name: 'TransportPanelTracks',

  components: {
    TransportPanelTimeline,
    TransportPanelStrip,
  },

  props: {
    tracks: {
      // type: Array,
      required: true,
    },
    duration: {
      // type: Number,
      required: true,
    },
    step: {
      // type: Number,
      required: true,
    },
    horizontalBarHeight: {
      // type: Number,
      required: true,
    },
    tracksHeight: {
      // type: Number,
      required: true,
    },
  },

  data() {
    return {
      selectedStrips: [],
    };
  },

  computed: {
  },

  methods: {
    resizeStrip(trkId, trkIdx, stripIdx, strip) {
      // console.log('Resize strip');
      this.$emit('resizeStrip', trkId, trkIdx, stripIdx, strip);
    },
    resizeStripEnd(trkIdx, stripIdx, stripData) {
      // console.log('Resize strip End');
      this.$emit('resizeStripEnd', trkIdx, stripIdx, stripData);
    },
    dragStrip(trkId, trkIdx, stripIdx, strip) {
      // console.log('Drag strip');
      this.$emit('dragStrip', trkId, trkIdx, stripIdx, strip);
    },
    dragStripEnd(trkIdx, stripIdx, stripData) {
      // console.log('Drag strip End', stripData);
      this.$emit('dragStripEnd', trkIdx, stripIdx, stripData);
    },
    changeStripTrack(trkIdx, stripIdx, evt) {
      // console.log('Change strip track', evt);
      this.$emit('changeStripTrack', trkIdx, stripIdx, evt);
    },
    selectStrip(trkIdx, strip) {
      // console.log('selectStrip', trkIdx, strip);
      const data = { trkIdx, strip };
      this.selectedStrips = [data];
    },
  },

  watch: {
  },

  mounted() {
  },

  beforeDestroy() {
  },
};
</script>

<style lang="scss">
</style>
