<template>
  <div class="transport-headers-area-container" :style="{ width: `${headersAreaWidth}px` }">
    <!-- <div class="transport-headers-area"> -->
    <div
      class="transport-h-bar transport-headers-bar"
      :style="{ height: `${horizontalBarHeight}px` }"
    ></div>
    <div
      class="transport-track"
      v-for="track in tracks"
      :key="track.id"
      :style="{ height: `${track.height || tracksHeight}px` }"
    >
      <div class="transport-header">{{ track.name }}</div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'TransportPanelHeaders',

  props: {
    tracks: {
      // type: Array,
      required: true,
    },
    horizontalBarHeight: {
      // type: Number,
      required: true,
    },
    headersAreaWidth: {
      // type: Number,
      required: true,
    },
    tracksHeight: {
      // type: Number,
      required: true,
    },
  },

  data() {
    return {
    };
  },

  computed: {
  },

  methods: {
  },

  watch: {
  },

  mounted() {
  },

  beforeDestroy() {
  },
};
</script>

<style lang="scss">
</style>
