<template>
  <div class="transport-h-bar transport-timeline" :style="rulerStyle">
    <label
      class="tick"
      v-for="l in Math.ceil((duration * step) / 100)"
      :key="l"
    >{{ ((l - 1) / step).toFixed(Math.min(step, 2)) }}</label>
  </div>
</template>

<script>
export default {
  name: 'TransportPanelTimeline',

  props: {
    step: {
      // type: Number,
      required: true,
    },
    duration: {
      // type: Number,
      required: true,
    },
    horizontalBarHeight: {
      // type: Number,
      required: true,
    },
  },

  data() {
    return {
    };
  },

  computed: {

    rulerStyle() {
      const s = 1.5 / this.step;
      const r = {
        backgroundSize: `${100 * this.step}px ${this.horizontalBarHeight}px`,
        height: `${this.horizontalBarHeight}px`,
        backgroundImage: `linear-gradient(
          90deg,
          rgba(222, 222, 222, 0.6) 0,
          rgba(222, 222, 222, 0.6) ${s}%,
          transparent ${s}%
        ),
        linear-gradient(180deg, rgb(38, 38, 38) 50%, transparent 50%),
        linear-gradient(
          90deg,
          transparent 50%,
          rgba(222, 222, 222, 0.6) 50%,
          rgba(222, 222, 222, 0.6) ${50 + s}%,
          transparent ${50 + s}%
        ),
        linear-gradient(180deg, rgb(38, 38, 38) 70%, transparent 70%),
        linear-gradient(
          90deg,
          transparent 10%,
          rgba(222, 222, 222, 0.4) 10%,
          rgba(222, 222, 222, 0.4) ${10 + s}%,
          transparent ${10 + s}%
        ),
        linear-gradient(
          90deg,
          transparent 20%,
          rgba(222, 222, 222, 0.4) 20%,
          rgba(222, 222, 222, 0.4) ${20 + s}%,
          transparent ${20 + s}%
        ),
        linear-gradient(
          90deg,
          transparent 30%,
          rgba(222, 222, 222, 0.4) 30%,
          rgba(222, 222, 222, 0.4) ${30 + s}%,
          transparent ${30 + s}%
        ),
        linear-gradient(
          90deg,
          transparent 40%,
          rgba(222, 222, 222, 0.4) 40%,
          rgba(222, 222, 222, 0.4) ${40 + s}%,
          transparent ${40 + s}%
        ),
        linear-gradient(
          90deg,
          transparent 60%,
          rgba(222, 222, 222, 0.4) 60%,
          rgba(222, 222, 222, 0.4) ${60 + s}%,
          transparent ${60 + s}%
        ),
        linear-gradient(
          90deg,
          transparent 70%,
          rgba(222, 222, 222, 0.4) 70%,
          rgba(222, 222, 222, 0.4) ${70 + s}%,
          transparent ${70 + s}%
        ),
        linear-gradient(
          90deg,
          transparent 80%,
          rgba(222, 222, 222, 0.4) 80%,
          rgba(222, 222, 222, 0.4) ${80 + s}%,
          transparent ${80 + s}%
        ),
        linear-gradient(
          90deg,
          transparent 90%,
          rgba(222, 222, 222, 0.4) 90%,
          rgba(222, 222, 222, 0.4) ${90 + s}%,
          transparent ${90 + s}%
        )`,
      };

      return r;
    },
  },

  methods: {
  },

  watch: {
  },

  mounted() {
  },

  beforeDestroy() {
  },
};
</script>

<style lang="scss">
</style>
