<template>
  <div
    :class="['transport-strip', selected ? 'selected' : '', resizing ? 'resizing' : '', dragging ? 'dragging' : '']"
    :style="{
      left: `${strip.position * step}px`,
      width: `${strip.duration * step}px`,
    }"
    :title="`POS: ${strip.position} / DUR: ${strip.duration}`"
    @click="clicked"
    @mousedown="startDragging"
  >
    <div
      class="transport-strip-handle transport-handle-start"
      @mousedown="startResizing('start', $event)"
    ></div>

    <div class="transport-strip-body">
      <span class="transport-strip-id">Str {{ strip.id }}</span>
      <span
        class="transport-strip-data"
        NOstyle="{ display: this.resizing ? 'inline' : 'none' }"
        v-if="resizing || dragging || (step == 1 && strip.duration >= 200) || step > 1 && strip.duration >= 100"
      >
        <strong>POS:</strong>
        {{ strip.position }}
        /
        <strong>DUR:</strong>
        {{ strip.duration }}
      </span>
    </div>

    <div
      class="transport-strip-handle transport-handle-end"
      @mousedown="startResizing('end', $event)"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'TransportPanelStrip',

  props: {
    step: {
      // type: Number,
      required: true,
    },
    strip: {
      // type: Object,
      required: true,
    },
    track: {
      // type: Object,
      required: true,
    },
    selected: {
      // type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      resizing: false,
      dragging: false,
    };
  },

  computed: {
  },

  methods: {
    clicked(evt) {
      // console.log('Strip clicked', this.strip, evt);
      this.$emit('click', evt);
    },
    startResizing(direction, e) {
      // console.log('start resizing', this.$el);
      this.clicked(e);
      this.resizing = true;
      let mPos = e.x;
      const initialStripPosition = this.strip.position;
      const strip = { ...this.strip };

      const resize = (re) => {
        const dx = (mPos - re.x) / this.step;
        mPos = re.x;
        strip.duration = direction === 'start' ? strip.duration + dx : strip.duration - dx;
        if (direction === 'start') {
          // strip.position = (strip.position - dx).toFixed(1);
          strip.position -= dx;
          strip.position = strip.position < 0 ? 0 : strip.position;
        }

        // console.log('resizing - mPos', mPos, 'dx', dx, strip);
        this.$emit('resize', strip);
      };

      const handleMouseUp = () => {
        console.log('handleMouseUp');
        this.resizing = false;
        document.removeEventListener('mousemove', resize);
        document.removeEventListener('mouseup', handleMouseUp);
        this.$emit('resizeEnd', { strip, initialStripPosition });
      };

      document.addEventListener('mousemove', resize);
      document.addEventListener('mouseup', handleMouseUp);
    },

    startDragging(e) {
      if (this.resizing) {
        return;
      }

      this.clicked(e);

      this.dragging = true;
      let initialmPos = e.x;
      let mPos = e.x;
      const initialStripPosition = this.strip.position;
      // const initialmPosY = e.y;
      const strip = { ...this.strip };

      const drag = (re) => {
        const dx = (mPos - re.x) / this.step;
        mPos = re.x;
        // strip.position = (strip.position - dx).toFixed(1);
        if (initialmPos !== false) {
          const posDiff = Math.abs(mPos - initialmPos);

          if (posDiff > 30) {
            initialmPos = false;
          }
        }

        //   const yDiff = initialmPosY - re.y;
        //   // console.log('yDiff', yDiff);
        //   if (yDiff > 50) {
        //     initialmPosY = re.y;
        //     this.$emit('mouseup');
        //     this.$emit('changeTrack', -1);
        //   }
        //   if (yDiff < -50) {
        //     initialmPosY = re.y;
        //     this.$emit('mouseup');
        //     this.$emit('changeTrack', 1);
        //   }

        if (initialmPos === false) {
          strip.position -= dx;
          strip.position = strip.position < 0 ? 0 : strip.position;

          // console.log('dragging - mPos', mPos, 'dx', dx, strip);
          this.$emit('drag', strip);
        }
      };

      const handleMouseUp = () => {
        console.log('Drag - handleMouseUp');
        this.dragging = false;
        document.removeEventListener('mousemove', drag);
        document.removeEventListener('mouseup', handleMouseUp);
        this.$emit('dragEnd', { strip, initialStripPosition });
      };

      document.addEventListener('mousemove', drag);
      document.addEventListener('mouseup', handleMouseUp);
    },
  },

  watch: {
  },

  mounted() {
  },

  beforeDestroy() {
  },
};
</script>

<style lang="scss">
</style>
