<template>
  <div class="transport-panel" :style="{ height: height }">
    <!-- <div class="transport-toolbar-section">
        Toolbar
    </div>-->
    <div class="transport-viewport" :style="{ width: `${viewportWidth}px` }">
      <TransportPanelHeaders
        :tracks="tracks"
        :horizontalBarHeight="horizontalBarHeight"
        :headersAreaWidth="headersAreaWidth"
        :tracksHeight="tracksHeight"
      />

      <TransportPanelTracks
        :tracks="tracks"
        :step="step"
        :horizontalBarHeight="horizontalBarHeight"
        :tracksHeight="tracksHeight"
        :duration="duration"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import TransportPanelHeaders from './Headers.vue';
import TransportPanelTracks from './Tracks.vue';

export default {
  name: 'TransportPanel',

  components: {
    TransportPanelHeaders,
    TransportPanelTracks,
  },

  props: {
    tracks: {
      type: Array,
      required: true,
    },
    height: {
      default: '200px',
    },
    step: {
      // type: Number,
      default: 1.0,
    },
    tracksHeight: {
      // type: Number,
      default: 50,
    },
    horizontalBarHeight: {
      // type: Number,
      default: 30,
    },
    headersAreaWidth: {
      // type: Number,
      default: 200,
    },
    defaultduration: {
      // type: Number,
      default: 1000, // 10 seconds
    },
  },

  data() {
    return {
    };
  },

  computed: {
    duration() {
      // const d = this.tracks.reduce((a, t) => Math.max(a, t.strips.reduce((sa, s) => sa + s.duration, 0)), 0);
      const d = this.tracks.reduce((a, t) => {
        const stripMaxLen = t.strips.reduce((sa, s) => {
          const len = s.position + s.duration;
          // console.log('ACC', sa, 'position', s.position, 'duration', s.duration, 'len', s.position + s.duration);
          return Math.max(sa, len);
        }, 0);

        // console.log('Trac', t.id, 'ACC', a, 'Strip max len', stripMaxLen);

        return Math.max(a, stripMaxLen);
      }, 0);
      // console.log('SUM', d);
      return Math.max(d, this.defaultduration);
    },

    viewportWidth() {
      return (this.duration * this.step) + this.headersAreaWidth + 10;
    },
  },

  methods: {
  },

  watch: {
  },

  mounted() {
  },

  beforeDestroy() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "./transport-panel.scss";
</style>
