<template>
  <div class="about">
    <h2>Panel/Viewport test 1</h2>
    <div class="nav">
      <div>
        <div>Zoom (step):</div>
        <input type="number" min="0.5" max="10.0" step="0.1" v-model="zoom" />
        <input type="range" min="0.5" max="10.0" step="0.1" v-model="zoom" class="slider" />
        <button @click="zoom = 0.5">50%</button>
        <button @click="zoom = 1.0">100%</button>
        <button @click="zoom = 2">200%</button>
        <button @click="zoom = 5">500%</button>
      </div>

      <div>
        <div>Tracks height:</div>
        <input type="number" min="25" max="500" v-model="tracksHeight" />
        <input type="range" min="25" max="500" v-model="tracksHeight" class="slider" />
        <button @click="tracksHeight = 25">Small</button>
        <button @click="tracksHeight = 50">Normal</button>
        <button @click="tracksHeight = 200">Big</button>
        <button @click="tracksHeight = 500">Huge</button>
      </div>
      <div>
        <div>Action Mode:</div>
        <input readonly v-model="actionMode" />
        <button @click="actionMode = 'resizeSelf'">Resize Self</button>
        <button @click="actionMode = 'resizeOthers'">Resize Others</button>
        <button @click="actionMode = 'push'">Push</button>
        <button @click="actionMode = 'none'">None</button>
      </div>
    </div>
    <TransportPanel
      :tracks="tracks"
      height="50vh"
      :step="zoom"
      :tracksHeight="tracksHeight"
      :horizontalBarHeight="30"
      :headersAreaWidth="200"
      :defaultduration="1000"
      @resizeStrip="resizeStrip"
      @resizeStripEnd="actionEnd"
      @dragStrip="dragStrip"
      @dragStripEnd="actionEnd"
      @changeStripTrack="changeStripTrack"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import TransportPanel from '@/components/transport-panel/TransportPanel.vue';

export default {
  name: 'Transport',
  components: {
    TransportPanel,
  },

  data() {
    return {
      actionMode: 'push', // resizeSelf, resizeOthers, push, none
      zoom: 1.0,
      tracksHeight: 50,
      tracks: [
        {
          id: 1,
          name: 'Track 1',
          strips: [
            {
              id: 1,
              position: 10,
              duration: 150,
            },
            {
              id: 2,
              position: 160,
              duration: 200,
            },
            {
              id: 3,
              position: 360,
              duration: 300,
            },
          ],
        },
        {
          id: 2,
          name: 'Track 2',
          strips: [
            {
              id: 1,
              position: 0,
              duration: 250,
            },
            {
              id: 2,
              position: 260,
              duration: 200,
            },
            {
              id: 3,
              position: 460,
              duration: 150,
            },
            {
              id: 4,
              position: 610,
              duration: 450,
            },
          ],
        },
        {
          id: 3,
          name: 'Track 3',
          strips: [
            {
              id: 1,
              position: 300,
              duration: 200,
            },
            {
              id: 2,
              position: 500,
              duration: 200,
            },
            {
              id: 3,
              position: 700,
              duration: 150,
            },
            {
              id: 4,
              position: 850,
              duration: 150,
            },
          ],
        },
        {
          id: 4,
          name: 'Track 4',
          strips: [
            {
              id: 1,
              position: 300,
              duration: 200,
            },
            {
              id: 2,
              position: 500,
              duration: 200,
            },
            {
              id: 3,
              position: 700,
              duration: 150,
            },
            {
              id: 4,
              position: 850,
              duration: 150,
            },
          ],
        },
        {
          id: 5,
          name: 'Track 5',
          strips: [
            {
              id: 1,
              position: 300,
              duration: 200,
            },
            {
              id: 2,
              position: 500,
              duration: 200,
            },
            {
              id: 3,
              position: 700,
              duration: 150,
            },
            {
              id: 4,
              position: 850,
              duration: 150,
            },
          ],
        },
        {
          id: 6,
          name: 'Track 6',
          strips: [
            {
              id: 1,
              position: 300,
              duration: 200,
            },
            {
              id: 2,
              position: 500,
              duration: 200,
            },
            {
              id: 3,
              position: 700,
              duration: 150,
            },
            {
              id: 4,
              position: 850,
              duration: 150,
            },
          ],
        },
      ],
    };
  },

  methods: {
    reorderStrips(trkIdx) {
      const track = this.tracks[trkIdx];
      track.strips.sort((a, b) => a.position - b.position);
    },

    setStripData(trkIdx, stripIdx, strip) {
      this.$set(this.tracks[trkIdx].strips[stripIdx], 'position', strip.position);
      this.$set(this.tracks[trkIdx].strips[stripIdx], 'duration', strip.duration);
    },

    resizeStrip(trkId, trkIdx, stripIdx, strip) {
      // console.log('Resize strip AAA', trkId, trkIdx, stripIdx, strip);
      this.setStripData(trkIdx, stripIdx, strip);
    },
    dragStrip(trkId, trkIdx, stripIdx, strip) {
      // console.log('Drag strip AAA', trkId, trkIdx, stripIdx, strip);
      this.$set(this.tracks[trkIdx].strips[stripIdx], 'position', strip.position);
    },
    actionEnd(trkIdx, stripIdx, { strip, initialStripPosition }) {
      // console.log('Action (Drag/Resize/...) END', trkIdx, stripIdx, strip, 'initialStripPosition', initialStripPosition);

      // set strip data
      this.setStripData(trkIdx, stripIdx, strip);

      // copy strip data for modifications
      const stripData = { ...strip };

      // reorder strips array
      this.reorderStrips(trkIdx);

      const track = this.tracks[trkIdx];

      // find new strip index
      const stripNewIdx = track.strips.findIndex((s) => s.id === strip.id);

      // find prev and next
      const isFirst = stripNewIdx === 0;
      const isLast = stripNewIdx === track.strips.length - 1;
      const nextStrip = isLast ? null : track.strips[stripNewIdx + 1];
      const prevStrip = isFirst ? null : track.strips[stripNewIdx - 1];

      switch (this.actionMode) {
        // cut this strip
        case 'resizeSelf':
          if (!isLast) {
            if ((stripData.position + stripData.duration) > nextStrip.position) {
              const newDur = nextStrip.position - stripData.position;
              stripData.duration = newDur;
            }
          }
          if (!isFirst) {
            // currentStrip "inside" prevStrip
            if (stripData.position > prevStrip.position && (prevStrip.position + prevStrip.duration) > (stripData.position + stripData.duration)) {
              // console.log('Strips overlap');
              stripData.position = initialStripPosition;
            } else if (stripData.position < (prevStrip.position + prevStrip.duration)) {
              const newDur = ((prevStrip.position + prevStrip.duration) - stripData.position);
              stripData.duration = newDur > 0 ? stripData.duration - newDur : stripData.duration;
              stripData.position = prevStrip.position + prevStrip.duration;
            }
          }

          // set new strip data
          this.setStripData(trkIdx, stripNewIdx, stripData);
          break;
        // cut other strips
        case 'resizeOthers':

          break;

        // move other strips
        case 'push':
          if (!isFirst) {
            const prevStrips = track.strips.slice(0, stripNewIdx);
            if (stripData.position < (prevStrip.position + prevStrip.duration)) {
              let overlap = (prevStrip.position + prevStrip.duration) - stripData.position;
              let count = 0;
              prevStrips.forEach((s) => {
                const prevStripIdx = count;
                count += 1;
                const data = { ...s };

                data.position -= overlap;
                if (data.position < 0) {
                  // adjust current strip's position
                  stripData.position += overlap - (overlap + data.position);
                  this.setStripData(trkIdx, stripNewIdx, stripData);

                  // new overlap
                  overlap -= Math.abs(data.position);
                  data.position = 0;
                }
                this.setStripData(trkIdx, prevStripIdx, data);
              });
            }
          }
          if (!isLast) {
            const nextStrips = track.strips.slice(stripNewIdx + 1);
            if ((stripData.position + stripData.duration) > nextStrip.position) {
              const overlap = (stripData.position + stripData.duration) - nextStrip.position;
              let count = 0;
              nextStrips.forEach((s) => {
                count += 1;
                const nextStripIdx = stripNewIdx + count;
                const data = { ...s };
                data.position += overlap;
                this.setStripData(trkIdx, nextStripIdx, data);
              });
            }
          }

          // // set new strip data
          // this.setStripData(trkIdx, stripNewIdx, stripData);
          break;

        default:
          console.log('No action mode');
      }
    },

    changeStripTrack(trkIdx, stripIdx, evt) {
      console.log('Change strip track', evt);
      const strip = this.tracks[trkIdx].strips.splice(stripIdx, 1)[0];
      // console.log('STRIP', strip);
      this.tracks[trkIdx + evt].strips.push(strip);
    },
  },
};
</script>
